import { onReady } from './utils/onReady';
import { isTouch, initVhUnitOverwrite } from './utils/helpers';
import { setupHeader } from './components/header';
import { setupNav } from './components/nav';
import { setupLazyLoading } from './utils/lazyImage';
import { setupIntersect } from './components/intersect';
import { setupAnchorLinkScroll } from './components/anchors';
import { setupSearch } from './components/search';
import { setupAccordions } from './components/accordion';
import { setupVideos } from './components/video';
import { setupFilters } from './components/filter';
import { setupSliders } from './components/slider';
import { setupServiceOverview } from './components/service-overview';
import { setupImageText } from './components/image-text';
import { watchFormFields } from './components/form';

function init() {

    document.body.classList.remove('standby');

    // Detect touch
    if (isTouch) {
        document.body.classList.add('touch-device');
    }

    onReady(() => {
        initVhUnitOverwrite();
        setupHeader();
        setupNav();
        setupLazyLoading();
        setupIntersect();
        setupAnchorLinkScroll();
        setupSearch();
        setupAccordions();
        setupVideos();
        setupFilters();
        setupSliders();
        setupServiceOverview();
        setupImageText();
        watchFormFields();
    });

    window.addEventListener('load', () => {
        // Polyfill for using svg spritesheet in oldIE
        svg4everybody();
    });
}

init();
