import { scrollTop, onScroll } from '../utils/scroll';
import { getElementPosition, getElementSize } from '../utils/elementProperties';

let header;

export function setupHeader() {

    if (document.querySelector('.header')) {

        header = document.querySelector('.header');

        const regex = /v\d/gm;
        const version = header.className.match(regex) != null ? header.className.match(regex)[0] : '';
        const hasImage = header.classList.contains('header--w-image');
        const startPoint = getElementPosition(header).top;
        const endPoint = startPoint + getElementSize(header).height;

        onScroll(() => {

            if (hasImage && (scrollTop >= startPoint && scrollTop < endPoint)) {
                switch (version) {
                    case 'v1':
                        handleVersion1();
                        break;
                    case 'v2':
                        handleVersion2();
                        break;
                }
            }
        });
    }
}

function handleVersion1() {

    const content = header.querySelector('.header__content');
    const imageContainer = header.querySelector('.header__image');

    if (window.innerWidth > window.innerHeight || window.innerWidth >= 768) {

        if (scrollTop > 0) {
            header.classList.add('animated');
        }

        if (content) {
            content.style.transform = `translate(0, ${scrollTop / 5}px)`;
        }

        let calculatedTranslateForImageContainer = scrollTop / 3;
        if (calculatedTranslateForImageContainer > 300) {
            calculatedTranslateForImageContainer = 300;
        }

        if (imageContainer) {
            imageContainer.style.transform = `translate(0, ${0 - calculatedTranslateForImageContainer}px)`;
        }

    } else {

        if (content) {
            content.style.transform = `translate(0, ${scrollTop / 4}px)`;
        }
    }
}

const startPoints = {
    mask: null,
    figure: null
};

function handleVersion2() {

    const content = header.querySelector('.header__content');
    const cta = content.querySelector('.header__cta');
    const mask = header.querySelector('.header__image-mask');
    const figure = mask.querySelector('figure');

    if (mask && figure) {
        const maskMatrix = new WebKitCSSMatrix(window.getComputedStyle(mask).transform);
        const figureMatrix = new WebKitCSSMatrix(window.getComputedStyle(figure).transform);

        let maskTranslate = maskMatrix.e;
        let figureTranslate = figureMatrix.e;

        if (startPoints.mask) {
            maskTranslate = startPoints.mask;
        } else {
            startPoints.mask = maskTranslate;
        }

        if (startPoints.figure) {
            figureTranslate = startPoints.figure;
        } else {
            startPoints.figure = figureTranslate;
        }

        maskTranslate = maskTranslate - scrollTop;

        if (maskTranslate < 0) {
            maskTranslate = 0;
        }

        figureTranslate = figureTranslate + scrollTop;

        if (figureTranslate > 0) {
            figureTranslate = 0;
        }

        mask.style.transform = `translate(${maskTranslate}px, 0)`;
        figure.style.transform = `translate(${figureTranslate}px, 0)`;
    }

    if (content) {
        content.style.transform = `translate(0,${-(scrollTop / 12)}px)`;
    }

    if (cta) {
        cta.style.transform = `translate(0,${-(scrollTop / 6)}px)`;
    }
}
