
import Flickity from '../../../node_modules/flickity-imagesloaded';
import { currentWindowWidth } from '../utils/windowResize';
import { lazyLoad } from '../utils/lazyImage';

/**
 * Default element selector is data-action^="slider"
 *
 * @param {HTMLElement} container
 * @constructor
 */
export class Slider {

    /**
     * The constructor is fired once the class is instantiated.
     *
     * @param {HTMLElement} container
     */
    constructor(container) {
        this.container = container;

        if (container) {
            const scope = this;
            const mobileMode = !(currentWindowWidth > 480);
            const prevNext = this.container.parentNode.parentNode.querySelectorAll('.btn__prev, .btn__next');

            let lazyImgs = Array.from(this.container.querySelectorAll('.lazy'));

            this.flkty = new Flickity(container, {
                adaptiveHeight: true,
                autoPlay: parseInt(container.getAttribute('data-autoplay')) || false,
                cellAlign: 'left',
                contain: true,
                draggable: true,
                friction: 0.20,
                initialIndex: 0,
                imagesLoaded: true,
                lazyLoad: mobileMode ? 2 : 3,
                pageDots: false,
                prevNextButtons: false,
                percentPosition: true,
                selectedAttraction: 0.025,
                setGallerySize: true,
                wrapAround: false,
                on: {
                    ready: () => {
                        lazyImgs = lazyImgs.filter(x => !x.classList.contains('lazy--loaded') || !x.classList.contains('lazy--loading'));

                        if (prevNext.length > 1) {
                            prevNext[0].addEventListener('click', function (event) {
                                scope.flkty.previous();
                                enableDisableBtn(prevNext, scope);
                            });
                            prevNext[1].addEventListener('click', function (event) {
                                scope.flkty.next();
                                enableDisableBtn(prevNext, scope);
                            });
                        }
                    },
                    change: () => {
                        if (lazyImgs.length) {
                            lazyLoad();
                        }
                        enableDisableBtn(prevNext, scope);
                    }
                }
            });
        }
    }
}

function enableDisableBtn(prevNext, scope) {

    if (prevNext.length) {

        if (scope.flkty.selectedIndex === scope.flkty.cells.length - 1) {
            prevNext[1].classList.add('disabled');
        } else {
            prevNext[1].classList.remove('disabled');
        }

        if (scope.flkty.selectedIndex === 0) {
            prevNext[0].classList.add('disabled');
        } else {
            prevNext[0].classList.remove('disabled');
        }
    }
}

export function setupSliders(selector = '[data-action^="slider"]') {
    const sliders = document.body.querySelectorAll(selector);

    for (let i = 0; i < sliders.length; i++) {
        void new Slider(sliders[i]);
    }
}
