import { enableScrollLock, disableScrollLock } from '../utils/scrollLock';

export let searchModal,
    searchModalForm,
    searchModalInput,
    searchInputs,
    searchToggleBtn;

export function showSearchOverlay() {
    enableScrollLock();
    document.body.classList.add('search--open');
}

export function hideSearchOverlay() {
    disableScrollLock();
    document.body.classList.remove('search--open');
    searchModalInput.blur();
    searchModalInput.value = '';
}

export function setupSearch() {

    searchModal = document.body.querySelector('.search__modal');
    searchModalForm = document.getElementById('search-modal-form');
    searchModalInput = document.getElementById('search-modal-field');
    searchInputs = document.body.querySelectorAll('.search__field');
    searchToggleBtn = document.body.querySelector('.search-toggle');

    if (searchModal && searchModalForm && searchModalInput && searchToggleBtn) {

        searchToggleBtn.addEventListener('click', showSearchOverlay);
        searchModal.addEventListener('click', function(e) {
            if (!searchModalForm.contains(e.target)) {
                hideSearchOverlay();
            }
        });

        document.addEventListener('keyup', function (e) {
            // escape key.
            if (e.keyCode == 27) {
                hideSearchOverlay();
            }
        });
    }

    if (searchInputs) {
        for (let i = 0; i < searchInputs.length; i++) {
            searchInputs[i].addEventListener('focus', () => {
                document.body.classList.add('search--active');
                // Set the cursor to end of the input
                const val = searchInputs[i].value;
                searchInputs[i].value = '';
                searchInputs[i].value = val;
            });
            searchInputs[i].addEventListener('blur', () => {
                document.body.classList.remove('search--active');
            });
        }
    }
}
