export function setupServiceOverview() {

    if (document.querySelector('.service-overview')) {
        const services = document.querySelectorAll('.service-overview__list-item');

        Array.from(services).forEach(service => {
            let animationTimeout;

            service.addEventListener('mouseenter', () => {
                animationTimeout = setTimeout(() => {
                    service.classList.add('active');
                }, 650);
            });

            service.addEventListener('mouseleave', () => {
                clearTimeout(animationTimeout);
                service.classList.remove('active');
            });
        });
    }
}
