import { onScroll, scrollTop } from '../utils/scroll';
import { currentWindowHeight, currentWindowWidth } from '../utils/windowResize';

export function setupImageText() {
    const paralaxImageWraps = document.querySelectorAll('[data-action="parallax-image"]');
    const mdMq = window.matchMedia('(min-width: 1024px)');
    const buffer = currentWindowHeight / 1.25;
    const hiddenPart = 175;

    Array.from(paralaxImageWraps).forEach(imageWrap => {
        const image = imageWrap.querySelector('img');
        const imageWrapHeight = imageWrap.offsetHeight;
        const imageHeight = image.offsetHeight;
        const top = imageWrap.getBoundingClientRect().top + scrollTop - buffer;
        const maxY = (imageHeight - imageWrapHeight) + (((hiddenPart / 1920) * currentWindowWidth) * 1);

        image.setAttribute('data-top', top);
        image.setAttribute('data-max-y', maxY);
    });

    onScroll(() => {
        Array.from(paralaxImageWraps).forEach(imageWrap => {
            const image = imageWrap.querySelector('img');
            const top = image.getAttribute('data-top');
            const maxY = image.getAttribute('data-max-y');
            const newY = mdMq.matches ? (scrollTop - top) / 2.5 : (scrollTop - top) / 5;
            const translateY = newY <= maxY ? newY : maxY;
            image.style.transform = `translateZ(0) translateY(${translateY}px)`;

            if (scrollTop >= top) {
                image.style.transform = `translateZ(0) translateY(${translateY}px)`;
            } else {
                image.style.transform = null;
            }
        });
    });
}
