import { stickyNavOnScroll } from '../utils/stickyNavOnScroll';
import { enableScrollLock, disableScrollLock } from '../utils/scrollLock';
import { hideOnClickOutside } from '../utils/clickOutside';

export let nav,
    logo,
    languageSelector,
    languageSelectorBtn;

export function toggleMenuOpen() {
    if (nav.classList.contains('nav--open')) {
        disableScrollLock();
        nav.classList.remove('nav--open');

        if (window.pageYOffset > 50) {
            setTimeout(() => nav.classList.add('going-up'), 40);
        }

    } else {
        enableScrollLock();
        nav.classList.add('nav--open');
    }
}

export function openLink(e) {
    e.preventDefault();
    const link = e.currentTarget.getAttribute('data-href');
    if (link) {
        window.location = link;
    }
}

export function setupNav(selector = '.nav', sticky = true) {

    nav = document.body.querySelector(selector);
    logo = document.body.querySelector('.nav__logo');
    languageSelector = document.body.querySelector('.nav__language-selector__current');
    languageSelectorBtn = document.body.querySelector('.nav__language-selector');

    if (nav) {
        const navBtn = nav.querySelector('.nav__btn');

        if (navBtn) {
            navBtn.addEventListener('click', toggleMenuOpen);
        }

        if (sticky) {
            stickyNavOnScroll(nav, 'nav--sticky', 30, 'going-up');
        }
    }

    if (logo) {
        logo.addEventListener('click', openLink);
    }

    if (languageSelector && languageSelectorBtn) {
        languageSelector.addEventListener('click', function() {
            nav.classList.add('nav--language-selector-open');
            hideOnClickOutside(languageSelectorBtn, function() {
                nav.classList.remove('nav--language-selector-open');
            });
        });
    }

}
