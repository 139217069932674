import { scrollTop } from '../utils/scroll';
import { isIE11 } from '../utils/helpers';

export function setupIntersect() {

    const intersectElements = document.querySelectorAll('[data-intersect]');

    // IE11 fallback
    if (isIE11) {

        Array.from(intersectElements).forEach(element => {
            element.classList.add('in-viewport', 'scrolled-by');
        });

    } else {

        const options = {
            root: null,
            rootMargin: '0px',
            threshold: [0.2, 0.4]
        };

        const observer = new IntersectionObserver(handleIntersect, options);

        Array.from(intersectElements).forEach(element => {
            const elementTop = element.getBoundingClientRect().top + scrollTop;

            element.setAttribute('data-top', elementTop);
            observer.observe(element);
        });
    }

    function handleIntersect(changes) {

        changes.forEach(change => {

            if (change.intersectionRatio > 0.2) {
                change.target.classList.add('in-viewport');
                change.target.classList.remove('scrolled-by');
            }

            if (change.intersectionRatio < 0.4 && change.target.classList.contains('in-viewport')) {
                const elementTop = parseInt(change.target.getAttribute('data-top'));

                if (scrollTop > elementTop) {
                    change.target.classList.add('in-viewport', 'scrolled-by');
                } else {
                    change.target.classList.remove('scrolled-by');
                }
            }
        });
    }
}
